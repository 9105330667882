<template>
  <v-content class="backgroundImg" data-triangle-container>
    <v-container>
      <div class="ml-5 mx-xl-12 px-xl-12 pt-6 d-flex align-center">
        <p style="color:#62579d" class="display-1 font-weight-medium mb-0">
          Ergebnisse
        </p>
      </div>
      <div
        class="mt-6 mx-xl-12 px-xl-12 my-12 mb-0 pb-5 pb-2 pb-sm-10 py-10 align-center"
      >
        <v-row class="mt-12">
          <v-col cols="12" sm="12" md="12" lg="7">
            <p class="text-center mt-7 mb-10"></p>
            <p class="text-center">
              <Triangle
                :width="width"
                mr="100"
                ml="95"
                mb="80"
                mt="100"
                :p1="p1"
                :p2="p2"
                :p3="p3"
                :key="componentKey"
              />
            </p>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="5">
            <v-card tile class="ma-3 ma-sm-8 py-4" v-if="note">
              <v-card-text class="d-flex">
                <v-img
                  aspect-ratio="1"
                  src="/kind.svg"
                  width="40"
                  height="40"
                  contain
                  class="mr-6"
                ></v-img>
                <p class="mb-0" style="width:70%">
                  <strong>Kind</strong><br />
                  <strong>{{ note.enfant }} Punkte / 20</strong>
                </p>
                <v-img
                  aspect-ratio="1"
                  src="/mal.svg"
                  width="30"
                  height="30"
                  contain
                  class="mr-2 align-self-center"
                  v-if="note.enfant <= 6"
                ></v-img>
                <v-img
                  aspect-ratio="1"
                  src="/moyen.svg"
                  width="30"
                  height="30"
                  contain
                  class="mr-2 align-self-center"
                  v-if="note.enfant >= 7 && note.enfant <= 12"
                ></v-img>
                <v-img
                  aspect-ratio="1"
                  src="/bien.svg"
                  width="30"
                  height="30"
                  contain
                  class="mr-2 align-self-center"
                  v-if="note.enfant >= 13"
                ></v-img>
              </v-card-text>
              <v-card-text class="d-flex">
                <v-img
                  aspect-ratio="1"
                  src="/kontext.svg"
                  width="40"
                  height="40"
                  contain
                  class="mr-6"
                ></v-img>
                <p class="mb-0" style="width:70%">
                  <strong>Kontext</strong><br />
                  <strong>{{ note.environnement }} Punkte / 20</strong>
                </p>
                <v-img
                  aspect-ratio="1"
                  src="/mal.svg"
                  width="30"
                  height="30"
                  contain
                  class="mr-2 align-self-center"
                  v-if="note.environnement <= 6"
                ></v-img>
                <v-img
                  aspect-ratio="1"
                  src="/moyen.svg"
                  width="30"
                  height="30"
                  contain
                  class="mr-2 align-self-center"
                  v-if="note.environnement >= 7 && note.environnement <= 12"
                ></v-img>
                <v-img
                  aspect-ratio="1"
                  src="/bien.svg"
                  width="30"
                  height="30"
                  contain
                  class="mr-2 align-self-center"
                  v-if="note.environnement >= 13"
                ></v-img>
              </v-card-text>
              <v-card-text class="d-flex">
                <v-img
                  aspect-ratio="1"
                  src="/konsum.svg"
                  width="40"
                  height="40"
                  contain
                  class="mr-6"
                ></v-img>
                <p class="mb-0" style="width:70%">
                  <strong>Konsum</strong><br />
                  <strong>{{ note.ecrans }} Punkte / 20</strong>
                </p>
                <v-img
                  aspect-ratio="1"
                  src="/mal.svg"
                  width="30"
                  height="30"
                  contain
                  class="mr-2 align-self-center"
                  v-if="note.ecrans <= 6"
                ></v-img>
                <v-img
                  aspect-ratio="1"
                  src="/moyen.svg"
                  width="30"
                  height="30"
                  contain
                  class="mr-2 align-self-center"
                  v-if="note.ecrans >= 7 && note.ecrans <= 12"
                ></v-img>
                <v-img
                  aspect-ratio="1"
                  src="/bien.svg"
                  width="30"
                  height="30"
                  contain
                  class="mr-2 align-self-center"
                  v-if="note.ecrans >= 13"
                ></v-img>
              </v-card-text>
              <div
                class="mx-7 my-3 pt-5"
                style="border-top: 1px solid #62579d;"
              >
                <h2 class="mb-4 mt-4">Empfehlungen</h2>
                <p style="color:#333333;">
                  Der Umgang mit Bildschirmen kann unter drei Gesichtspunkten beurteilt werden:
                  <ul>
                    <li><strong>Kind:</strong> Regeln an die Persönlichkeit und das Alter Ihres Kindes anpassen</li>
                    <li><strong>Kontext:</strong> auf Orte und die Art und Weise der Bildschirmnutzung achten </li>
                    <li><strong>Konsum:</strong> über digitale Medien, die genutzt werden und die Verfügbarkeit von Bildschirmen nachdenken</li>
                  </ul>
                  <br /><strong
                    class="mt-3 d-flex"
                    >Nun laden wir Sie ein, die Empfehlungen im Detail zu lesen.</strong
                  ><br /><v-btn
                    href="/infos"
                    color="#62579d"
                    class="white--text mt-4"
                    >Empfehlungen lesen</v-btn
                  >
                </p>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div class="my-12 mx-xl-12 px-xl-12 ml-0 mt-0">
        <v-card class="ma-3 ma-sm-8 my-12 mt-0 px-8 py-5">
          <h2>....Machen Sie den Test in ein paar Monaten noch einmal!</h2>
          <p class="pa-0" style="color:#333333;">
            Wir gratulieren Ihnen, dass Sie sich die Zeit genommen haben, diesen Test zu absolvieren und den Umgang mit Bildschirmen in Ihrer Familie zu reflektieren. Das zeigt, dass Sie sich für die Thematik der Bildschirme und insbesondere für das Wohlbefinden Ihres Kindes Interessieren. Wir hoffen, dass Ihnen dieser Test und die Empfehlungen in Ihrer Elternrolle auch in Zukunft von Nutzen sein werden!
            Weitere Informationen zu Bildschirmen finden Sie auf unserer Website:
            <a href="https://www.praevention-medien.ch" target="_blank"
              >praevention-medien.ch</a
            >. Für weitere Fragen stehen wir gerne zu Ihrer Verfügung.
          </p>
        </v-card>
      </div>
    </v-container>
  </v-content>
</template>

<script>
import apiCall from '@/utils/api.js'
import Triangle from '@/components/Triangle'
export default {
  name: 'Home',
  data() {
    var width = 600
    if(document.querySelector('body').offsetWidth < 600){
      width = 300
    }
    return {
      pole: '',
      x: 0,
      y: 0,
      r: 200,
      p1: 0,
      p2: 0,
      p3: 0,
      width: width,
      componentKey: 1,
      advises: [],
      note: [],
      loading: true,
      poleTxt: ''
    }
  },
  mounted() {

    apiCall({
      url:
        '/responses?order[note]=asc&feedback=' +
        this.$store.getters.getFeedback,
      method: 'get'
    }).then(resp => {

      let note = []
      let nb = []

      note['ecrans'] = 0
      note['enfant'] = 0
      note['environnement'] = 0

      nb['ecrans'] = 0
      nb['enfant'] = 0
      nb['environnement'] = 0

      resp.data.forEach(response => {
        note[response.category] += response.note
        nb[response.category]++
      })

      this.p1 = note['environnement'] / (4 * nb['environnement'])
      this.p2 = note['ecrans'] / (4 * nb['ecrans'])
      this.p3 = note['enfant'] / (4 * nb['enfant'])

      if (this.p1 <= this.p2 && this.p1 < this.p3) {
        this.pole = 'environnement'
        this.poleTxt = 'environnement'
      } else if (this.p2 <= this.p1 && this.p2 < this.p3) {
        this.pole = 'ecrans'
        this.poleTxt = 'écrans'
      } else {
        this.pole = 'enfant'
        this.poleTxt = 'enfant'
      }

      this.note = note

      this.componentKey = 2

      // apiCall({
      //   url: '/feedback/' + this.$store.getters.getFeedback,
      //   method: 'put',
      //   data: { status: 1 }
      // }).then(resp => {
      //   this.loading = false
      //   if (
      //     document.querySelector('[data-triangle-container]').offsetWidth < 600
      //   ) {

      //      this.width = parseInt(
      //        document.querySelector('[data-triangle-container]').offsetWidth *
      //          0.8
      //     )
      //   }
      // })
    })
  },
  components: {
    Triangle
  }
}
</script>
