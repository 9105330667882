<template>
  <v-content class="backgroundImg">
    <v-container
      class="d-flex align-center justify-center"
      style="height: 100%"
    >
      <div
        class="mt-10 ma-3 ma-sm-12 px-sm-7 py-10 d-flex flex-column justify-center align-center white--text"
      >
        <v-btn
          class="my-2 d-flex d-sm-none button-absolute"
          color="secondary"
          dark
          href="/infos"
          >plus d'infos sur le Triangle des 3e</v-btn
        >
        <h1 color="primary" class="text-center title-page">
          Das Dreieck der 3K's, für den klugen Kompetenzaufbau in der digitalen Welt.
        </h1>
        <p class="text-center mt-7 mb-5" style="font-size: 20px">
          Bildschirme ziehen immer stärker in unser Familienleben ein. Wie gehen Sie mit dieser Situation um? Machen Sie den Test!
        </p>

        <v-btn
          class="my-4 my-sm-10"
          color="primary"
          :to="{ name: 'Intro' }"
          dark
          x-large
        >
          Test
        </v-btn>
      </div>
    </v-container>
  </v-content>
</template>
<script>
export default {
  name: 'Home'
}
</script>

<style scope>
h1,
h2,
p {
  color: #62579d;
}

@media screen and (max-width: 599px) {
  .button-absolute {
    position: absolute;
    top: 10px;
    /* left: 10px; */
    font-size: 13px !important;
    padding: 0 5px !important;
  }
  .title-page {
    font-size: 25px !important;
  }
}
</style>