<template>
  <v-app>
    <v-app-bar app color="white" dark>
      <v-row class="d-flex align-center justify-space-between">
        <v-col>
          <router-link to="/">
            <v-img alt="Les trois eee" src="/logo.png" width="200px" contain />
          </router-link>
        </v-col>
        <v-col class="d-none d-sm-flex justify-end">
          <v-btn class="my-2" color="secondary" dark href="/infos">Mehr Informationen über das Dreieck der 3K's</v-btn>
        </v-col>
      </v-row>
    </v-app-bar>
    <span class="bg"></span>
    <router-view :key="$route.fullPath" />
    <v-footer dark color="secondary" style="background-blend-mode: multiply">
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="4" class="pa-2 caption">
            <p class="mb-1 pb-1" style="color:white">Kontakte:</p>
            <div class="d-flex align-end footer-mobile">
              <div>
                <div class="mr-10">
                  Myrta Isenschmid<br /><a
                    style="color:white"
                    href="mailto:myrta.isenschmid@reper-fr.ch"
                    >myrta.isenschmid@reper-fr.ch</a>
                </div>
                <div class="mt-2">
                  Valérie Lehman<br /><a
                    style="color:white"
                    href="mailto:valerie.lehman@reper-fr.ch">
                      valerie.lehman@reper-fr.ch
                    </a>
                </div>
              </div>
              <div class="ml-4 margin-mobile">
                <v-btn
                  href="https://praevention-medien.ch/"
                  target="_blank"
                  color="white"
                  class="font-weight-bold"
                  style="color: #62579d; border-radius:50px"
                  >praevention-medien.ch</v-btn
                >
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="8" class="pa-2 caption text-center">
            <div class="d-flex justify-center">
              <img alt="test" src="/pictos.svg" contain height="80" width="200" />
              <img alt="Partenaires" class="mx-15" src="/partners.svg" contain height="80" />
            </div>
            <p style="color:white; margin-top: 20px; text-transform: uppercase;">© Verein reper 2020. Alle Rechte vorbehalten.</p>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import { LOAD } from '@/store/index'

export default {
  name: 'App',
  data: () => ({
    //
  }),
  created() {
    this.$store
      .dispatch(LOAD)
      .then(() => {})
      .catch(() => {})
  }
}
</script>
<style lang="scss">
.bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url('/background.svg') repeat-x center bottom;
  transform: scale(1);
}

.v-application .secondary {
  background-blend-mode: multiply !important;
}

@media screen and (max-width: 510px) {
  .v-application .footer-mobile.align-end {
    flex-direction: column;
    align-items: flex-start !important;

    .margin-mobile {
      margin-left: 0 !important;
      margin-top: 20px;
    }
  }
}
</style>
