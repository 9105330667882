<template>
  <v-content>
    <v-container>
      <div
        style="height:calc(100vh - 300px)"
        v-if="loading"
        class="d-flex align-center justify-center"
      >
        <v-img max-width="100" max-height="100" src="/loader.gif"></v-img>
      </div>
      <v-card shaped class="ma-3 ma-sm-8" v-if="!loading">
        <div class="ml-3 ml-sm-5 pt-6 d-flex align-center">
          <div class="mr-3">
            <v-img aspect-ratio="1" :src="img" width="80" contain></v-img>
          </div>
          <p
            v-html="title"
            class="display-1 font-weight-medium mb-0 title-question"
            :class="'color-' + type"
          ></p>
        </div>

        <v-card-text class="px-0 px-sm-5">
          <div v-if="!loading">
            <v-list>
              <div v-for="item in items" :key="item.id">
                <v-list-item>
                  <v-list-item-content>
                    <p
                      class="subtitle-1 mt-6"
                      style="color:#333;font-weight:bold"
                    >
                      {{ item.questionDe }}
                    </p>
                    <v-slider
                      height="80"
                      v-model="rating[item.id]"
                      :tick-labels="tricks"
                      class="align-center"
                      max="4"
                      min="0"
                      step="0.5"
                      :color="color"
                      tracks-color="gray"
                      hide-details
                    ></v-slider>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list>
            <p class="ma-2 text-right">
              <v-btn class="ma-2 text-right" :color="color" @click="next()"
                >Weiter</v-btn
              >
            </p>
          </div>
          <div v-if="loading">
            <div v-for="n in 5" :key="n">
              <v-skeleton-loader
                class="mx-4 my-10 pa-0"
                type="text"
              ></v-skeleton-loader>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">Oups!</v-card-title>
          <v-card-text>Veuillez répondre à toutes les questions</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false"
              >Ok</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-content>
</template>

<script>
import apiCall from '@/utils/api.js'

export default {
  name: 'Question',
  props: ['type'],
  data() {
    return {
      tricks: ['Stimme überhaupt nicht zu', '', '', '', '', '', '', '', 'Stimme voll und ganz zu'],
      dialog: false,
      items: [],
      rating: [],
      color: '',
      title: '',
      feedback: Object,
      loading: true
    }
  },
  methods: {
    rate(rating) {
      let res = 0
      rating.forEach((note, question) => {
        if (
          note == '0' ||
          note == '0.5' ||
          note == '1' ||
          note == '1.5' ||
          note == '2' ||
          note == '2.5' ||
          note == '3' ||
          note == '3.5' ||
          note == '4'
        ) {
          res++
        }
      })
      if (res < 4) {
        this.loading = false
        this.dialog = true
        return -1
      }
      rating.forEach((note, question) => {
        apiCall({
          url:
            '/responses?feedback=' +
            this.$store.getters.getFeedback +
            '&question=' +
            question,
          method: 'get'
        }).then(resp => {
          let questObj = this.items[
            this.items.findIndex(element => element.id === question)
          ]

          if (questObj.inversed) {
            if (parseInt(note) == '4') {
              note = '0'
            } else if (note == '3.5') {
              note = '0.5'
            } else if (note == '3') {
              note = '1'
            } else if (note == '2.5') {
              note = '1.5'
            } else if (note == '1.5') {
              note = '2.5'
            } else if (note == '1') {
              note = '3'
            } else if (note == '0.5') {
              note = '3.5'
            } else if (note == '0') {
              note = '4'
            }
          }

          if (resp.data.length) {
            let data = {
              note: parseInt(note)
            }
            apiCall({
              url: '/responses/' + resp.data[0].id,
              method: 'put',
              data: data
            }).then(resp => {})
          } else {
            let data = {
              note: parseInt(note),
              question: '/api/questions/' + question,
              feedback: '/api/feedback/' + this.$store.getters.getFeedback
            }
            apiCall({
              url: '/responses',
              method: 'post',
              data: data
            }).then(resp => {})
          }
        })
      })
    },
    next() {
      this.loading = true

      let type = ''
      switch (this.type) {
        case 'enfant':
          type = 'environnement'
          break
        case 'environnement':
          type = 'ecrans'
          break
        case 'ecrans':
          type = ''
          break
      }

      if (this.rate(this.rating) == -1) {
        return false
      }

      if (type === '') {
        let th = this
        setTimeout(function() {
          th.$router.push({ name: 'Result' })
        }, 3000)
      } else {
        this.$router.push({ name: 'Question', params: { type: type } })
      }
    }
  },
  mounted() {
    if (this.type == 'ecrans') {
      this.title = 'Konsum'
      this.img = '/konsum.svg'
      this.color = 'primary'
    }
    if (this.type == 'enfant') {
      this.title = 'Kind'
      this.img = '/kind.svg'
      this.color = 'secondary'
    }
    if (this.type == 'environnement') {
      this.title = 'Kontext'
      this.img = '/kontext.svg'
      this.color = 'accent'
    }

    apiCall({ url: '/questions?category=' + this.type, method: 'get' }).then(
      resp => {
        function compare(a, b) {
          if (a.weight < b.weight) {
            return -1
          }
          if (a.weight > b.weight) {
            return 1
          }
          return 0
        }
        this.items = resp.data
        this.items.sort(compare)
        this.loading = false
      }
    )
  }
}
</script>


<style>
.color-enfant {
  color: #a6c700 !important;
}
.color-environnement {
  color: #fbb700 !important;
}
.color-ecrans {
  color: #62579d !important;
}

@media screen and (max-width: 599px) {
  .title-question.title-question {
    font-size: 20px !important;
  }
}
</style>