<template>
    <v-content class="backgroundImg">
      <v-container class="d-flex align-center justify-center" style="height: 100%">
        <div class="ma-3 ma-sm-12 px-sm-7 py-10 d-flex flex-column justify-center align-center white--text">
          <h1 color="primary" class="text-center title-page">Informationen zum Test</h1>
          <ul class="pl-0">
            <p class="text-center mt-5 mb-3" style="font-size: 18px">Die 3K's wurden insbesondere für Eltern von Kindern im Alter von 5 bis 10 Jahren konzipiert.</p>
            <p class="text-center mt-5 mb-3" style="font-size: 18px">Wenn Sie mehrere Kinder haben, sollten Sie für jedes Kind einen eigenen Test ausfüllen.</p>
            <p class="text-center mt-5 mb-3" style="font-size: 18px">Der Begriff "Bildschirme" umfasst Fernseher, Computer, Smartphones, Tablets und Spielkonsolen.</p>
            <p class="text-center mt-5 mb-3" style="font-size: 18px">Mit dem Test zu den 3K's können Sie eine Bestandsaufnahme zum Umgang mit Bildschirmen zu Hause machen.</p>
            <p class="text-center mt-5 mb-3" style="font-size: 18px">Der Test ist anonym und es sind zu keinem Zeitpunkt Rückschlüsse möglich.</p>
            <p class="text-center mt-5 mb-20 font-weight-bold" style="font-size: 18px">Es ist nicht einfach, einen guten Umgang mit all den Bildschirmen zu finden, die uns umgeben. Wir gratulieren Ihnen dazu, dass Sie diesen Test starten. Das zeigt, dass Sie sich für den Kompetenzaufbau in der digitalen Welt interessieren.</p>
          </ul>

          <v-btn
            class="my-10"
            color="primary"
            :to="{name:'Form'}"
            dark
            x-large
            >
            Weiter
          </v-btn>

        </div>
      </v-container>
    </v-content>
</template>
<script>
export default {
  name: 'Home'
}
</script>

<style scope>
h1,
h2,
p {
  color: #62579d;
}
@media screen and (max-width: 599px) {
  .title-page {
    font-size: 25px !important;
  }
}
</style>