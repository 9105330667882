<template>
  <div v-if="data">
    <svg :width="data.width" :height="data.height" class="triangle">
      <circle
        v-for="n in 5"
        :key="n"
        :cx="data.cx"
        :cy="data.cy"
        :r="data.unit * n"
        stroke="#ddd"
        stroke-width="1"
        fill="none"
      />

      <polygon
        :points="data.points"
        style="fill:rgba(0,0,0,0.20);stroke-width:1;stroke:#333;"
      />

      <circle
        :cx="data.x1"
        :cy="data.y1"
        r="12"
        stroke="#555"
        stroke-width="0"
        fill="#Fbb700"
      />

      <circle
        :cx="data.x2"
        :cy="data.y2"
        r="12"
        stroke="#555"
        stroke-width="0"
        fill="#65579D"
      />

      <circle
        :cx="data.x3"
        :cy="data.y3"
        r="12"
        stroke="#124126"
        stroke-width="0"
        fill="#A6C700"
      />

      <image :width="data.width-10" :height="data.height-10"
      xlink:href="/dreieck.svg" clip-path="url(#myCircle)" />
    </svg>
  </div>
</template>
<script>
export default {
  props: ['width', 'ml', 'mr', 'mt', 'mb', 'p1', 'p2', 'p3'],
  data() {
    return {
      data: Object
    }
  },
  created() {
    this.data.p1 = parseFloat(this.p1)
    this.data.p2 = parseFloat(this.p2)
    this.data.p3 = parseFloat(this.p3)

    this.data.mt = parseFloat(this.mt)
    this.data.mr = parseFloat(this.mr)
    this.data.mb = parseFloat(this.mb)
    this.data.ml = parseFloat(this.ml)

    this.data.width = parseFloat(this.width)
    this.data.w = this.data.width - this.data.mr - this.data.ml

    this.data.h = this.data.w / (2 * Math.sin(Math.PI / 3))
    this.data.unit = ( this.data.h / 5 )
    this.data.a = this.data.w / (2 * Math.tan(Math.PI / 3))

    this.data.height = this.data.h + this.data.a + this.data.mt + this.data.mb

    this.data.cx = this.data.w / 2 + this.data.ml
    this.data.cy = this.data.h + this.data.mt

    this.data.l1 = this.data.h * this.data.p1
    this.data.l2 = this.data.h * this.data.p2
    this.data.l3 = this.data.h * this.data.p3

    let Y = Math.sin(Math.PI / 6) * this.data.l1
    let X = Math.cos(Math.PI / 6) * this.data.l1
    this.data.x1 = this.data.cx - X
    this.data.y1 = this.data.cy + Y

    Y = Math.sin(Math.PI / 6) * this.data.l2
    X = Math.cos(Math.PI / 6) * this.data.l2
    this.data.x2 = this.data.cx + X
    this.data.y2 = this.data.cy + Y

    Y = this.data.l3
    this.data.x3 = this.data.cx
    this.data.y3 = this.data.cy - Y

    this.data.X1 = this.data.ml
    this.data.Y1 = this.data.h + this.data.a + this.data.mt
    this.data.X2 = this.data.w + this.data.ml
    this.data.Y2 = this.data.Y1
    this.data.X3 = this.data.ml + this.data.w / 2
    this.data.Y3 = this.data.mt

    this.data.points =
      this.data.x1 +
      ',' +
      this.data.y1 +
      ' ' +
      this.data.x2 +
      ',' +
      this.data.y2 +
      ' ' +
      this.data.x3 +
      ',' +
      this.data.y3

    let a = this.data.cy + this.data.a
  }
}
</script>
<style scoped>
.triangle {
  background: #fff;
}
</style>
