<template>
  <v-content class="backgroundImg">
    <v-container v-if="!$route.query.pole">
      <v-card class="py-5 px-2 px-sm-10 my-12">
        <h1 color="primary" class="mt-sm-6 mx-4 mx-sm-8 title-page-info">
          Wählen Sie die Regeln, die gut zu Ihrem Erziehungsstil passen
        </h1>
        <div class="enfant mx-4 mx-sm-8">
          <h2 class="mt-8 mb-1" style="font-size: 30px;font-weight:normal">
            Kind
          </h2>
          <p class="mt-2 mb-10" style="font-size: 18px; color: #333333;">
            <strong class="color-enfant mt-2" style="display:inline-block;"
              >Verbringen Sie Zeit mit Ihrem Kind.</strong
            >
            Mit Ihrem Kind Zeit zu verbringen und mit ihm zu spielen, hilft Ihnen, die Beziehung zu Ihrem
            Kind zu stärken. Ihr Kind fühlt sich dadurch sicherer, Sie lernen es besser kennen und können die
            Entwicklung in all seinen Lebensphasen unmittelbar begleiten.
            <br /><strong
              class="color-enfant mt-2"
              style="display:inline-block;"
              >Achten Sie darauf, dass sich Ihr Kind ausreichend bewegt.</strong
            >
            Vor dem Bildschirm ist Ihr Kind inaktiv. Bewegung ist für die Entwicklung und Gesundheit
            Ihres Kindes von entscheidender Bedeutung.<br />
            <strong
              class="color-enfant mt-2" style="display:inline-block;">Jedes Kind ist unterschiedlich. </strong>
              Neigt Ihr Kind zu impulsivem Verhalten? In diesem Fall ist es wichtig, klare Regeln für
              die Nutzung von Bildschirmen aufzustellen. Ihr Kind hält sich gut an die vereinbarten Regeln?
              In diesem Fall kann es angebracht sein, dem Kind mehr Autonomie zu übergeben.
            <br /><strong
              class="color-enfant mt-2"
              style="display:inline-block;"
              >Unterstützen Sie Ihr Kind dabei, eine gute Haltung vor dem Bildschirm einzunehmen (aufrechte Position, Distanz, usw.).</strong
            >
            Auf lange Sicht kann eine ungünstige Haltung vor Bildschirmen zu gesundheitlichen Problemen
            führen (z.B. Nacken- und Rückenschmerzen, müde Augen).
            <br /><strong
              class="color-enfant mt-2"
              style="display:inline-block;"
              >Seien Sie ein Vorbild für Ihr Kind.</strong
            >
            Achten Sie auf Ihre eigene Bildschirmnutzung und leben Sie die vereinbarten Regeln vor.
            Ihr Kind wird eher Ihr Verhalten nachahmen, als tun, was Sie sagen.
          </p>
        </div>
        <div class="environnement mx-4 mx-sm-8">
          <h2 class="mt-8 mb-1" style="font-size: 30px;font-weight:normal">
            Kontext
          </h2>
          <p class="mt-2 mb-10" style="font-size: 18px; color: #333333;">
            <strong
              class="color-environnement mt-2"
              style="display:inline-block;"
              >Schaffen Sie klare Rahmenbedingungen.</strong
            >
            Vereinbaren Sie einfache Regeln, die festlegen, wann, wo und wie Ihr Kind die verschiedenen
            Formen von Bildschirmen, die Sie zu Hause haben, nutzen kann.<br /><strong
              class="color-environnement mt-2"
              style="display:inline-block;"
              >Platzieren Sie Bildschirme in gemeinsamen Wohnräumen.</strong
            >
            Ihr Kind ist in seinem Zimmer besser geschützt, sofern keine Bildschirme vorhanden sind.
            Zudem gewinnt sein Schlaf an Qualität. Geniessen Sie jedoch auch am Esstisch gemeinsame
            bildschirmfreie Zeit. <br /><strong
              class="color-environnement mt-2"
              style="display:inline-block;"
              >Wählen Sie für die Nutzung von Bildschirmen den richtigen Moment.</strong
            >
            Wenn es draussen schön ist oder Ihr Kind Hausaufgaben zu erledigen hat, ist es sinnvoll,
            die Bildschirmnutzung auf später zu verschieben.<br /><strong
              class="color-environnement mt-2"
              style="display:inline-block;"
              >Nutzen Sie Bildschirme bevorzugt im Familien- oder Freundeskreis.</strong
            >
            Zu den Hauptrisikofaktoren der Bildschirmnutzung gehört die Isolation. Die Nutzung von
            Bildschirmen in der Familie oder mit Freunden und das Teilen dieser Momente halten die Beziehungen
            zu anderen aufrecht.
            <br /><strong
              class="color-environnement mt-2"
              style="display:inline-block;"
              >Geniessen Sie bildschirmfreie Zeiten.</strong
            >
            Schlafen, essen, spielen, lesen – solche Momente lassen sich ohne nebenbei laufende
            Bildschirme besser geniessen. Solche bildschirmfreien Zeiten zeigen uns, dass es auch ohne geht.
          </p>
        </div>
        <div class="ecran mx-4 mx-sm-8">
          <h2 class="mt-8 mb-1" style="font-size: 30px;font-weight:normal">
            Konsum
          </h2>
          <p class="mt-2 mb-10" style="font-size: 18px; color: #333333;">
            <strong class="color-ecrans mt-2" style="display:inline-block;"
              >Interessieren Sie sich für die digitalen Medien, die Ihr Kind nutzt.</strong
            >
            Dadurch können Sie mit Ihrem Kind in Verbindung bleiben und schaffen eine gute Grundlage,
            um über die Bildschirmnutzung zu sprechen.<br /><strong
              class="color-ecrans mt-2"
              style="display:inline-block;"
              >Stellen Sie sicher, dass die digitalen Medien zum Alter Ihres Kindes passen.</strong
            >
            Ihr Kind kann noch nicht einschätzen, ob ein Spiel, ein Film oder eine Bildschirmform
            für sein Alter geeignet ist. Für Alterseinstufungen gibt es verschiedene Richtwerte (z.B. die
            PEGI-Richtlinien für Videospiele). Smartphones sind für Kleinkinder (0-6 Jahre) ungeeignet.<br /><strong
              class="color-ecrans mt-2"
              style="display:inline-block;"
              >Bevorzugen Sie digitale Medien, die Aktivität fördern.</strong
            >
            Das Gehirn Ihres Kindes entwickelt sich durch Aktivitäten, die alle Sinne ansprechen.
            Passives Konsumieren von digitalen Medien fördert diese Entwicklung nicht.<br /><strong
              class="color-ecrans mt-2"
              style="display:inline-block;"
              >Unterstützen Sie Ihr Kind dabei, sich jeweils nur auf eine Tätigkeit zu fokussieren.</strong
            >
            Bildschirme vermitteln den Eindruck, dass man mehrere Sachen gleichzeitig
            effizient machen kann. Das ist ein Mythos. Lernen Sie Ihrem Kind von klein auf, seine
            Aufmerksamkeit auf nur eine Tätigkeit zu lenken.<br /><strong
              class="color-ecrans mt-2"
              style="display:inline-block;"
              >Begrenzen Sie die Verfügbarkeit von Bildschirmen.</strong
            >
            Bildschirme wirken auf Kinder sehr anziehend. Tragbare Bildschirme noch mehr,
            da sie überall hin mitgenommen werden können. Lenken Sie die Aufmerksamkeit Ihres Kindes
            auf bildschrimfreie Aktivitäten, indem Sie die Anzahl Bildschirme zu Hause begrenzen.
          </p>
        </div>
      </v-card>
    </v-container>

    <v-container v-if="$route.query.pole == 'enfant'">
      <v-card class="py-5 px-2 px-sm-10 my-12">
        <h1 color="primary" class="mt-sm-6 mx-4 mx-sm-8 title-page-info">
          Wählen Sie die Regeln, die gut zu Ihrem Erziehungsstil passen
        </h1>
        <div class="enfant mx-4 mx-sm-8">
          <h2
            class="mt-8 mb-1 size-title-big"
            style="font-size: 60px;font-weight:normal"
          >
            Kind
          </h2>
          <p
            class="mt-2 mb-10 size-body-big"
            style="font-size: 24px; color: #333333;"
          >
            <strong class="color-enfant mt-2" style="display:inline-block;"
              >Verbringen Sie Zeit mit Ihrem Kind.</strong
            >
            Mit Ihrem Kind Zeit zu verbringen und mit ihm zu spielen, hilft Ihnen, die Beziehung zu Ihrem
            Kind zu stärken. Ihr Kind fühlt sich dadurch sicherer, Sie lernen es besser kennen und können die
            Entwicklung in all seinen Lebens phasen unmittelbar begleiten.
            <br /><strong
              class="color-enfant mt-2"
              style="display:inline-block;"
              >Achten Sie darauf, dass sich Ihr Kind ausreichend bewegt.</strong
            >
            Vor dem Bildschirm ist Ihr Kind inaktiv. Bewegung ist für die Entwicklung und Gesundheit
            Ihres Kindes von entscheidender Bedeutung.
            <br />
            <strong
              class="color-enfant mt-2" style="display:inline-block;">Jedes Kind ist unterschiedlich. </strong>
              Neigt Ihr Kind zu impulsivem Verhalten? In diesem Fall ist es wichtig, klare Regeln für
              die Nutzung von Bildschirmen aufzustellen. Ihr Kind hält sich gut an die vereinbarten Regeln?
              In diesem Fall kann es angebracht sein, dem Kind mehr Autonomie zu übergeben.
            <br /><strong
              class="color-enfant mt-2"
              style="display:inline-block;"
              >Unterstützen Sie Ihr Kind dabei, eine gute Haltung vor dem Bildschirm einzunehmen (aufre chte Position, Distanz, usw.).</strong
            >
            Auf lange Sicht kann eine ungünstige Haltung vor Bildschirmen zu gesundheitlichen Problemen führen (z.B. Nacken- und
            Rückenschmerzen, müde Augen).
             <br /><strong
              class="color-enfant mt-2"
              style="display:inline-block;"
              >Seien Sie ein Vorbild für Ihr Kind.</strong
            >
            Achten Sie auf Ihre eigene Bildschirmnutzung und leben Sie die vereinbarten Regeln vor.
            Ihr Kind wird eher Ihr Verhalten
            nachahmen, als tun, was Sie sagen.
          </p>
        </div>
       </v-card>
    </v-container>

    <v-container v-if="$route.query.pole == 'ecrans'">
      <v-card class="py-5 px-2 px-sm-10 my-12">
        <h1 color="primary" class="mt-sm-6 mx-4 mx-sm-8 title-page-info">
          Choisissez les règles qui sont proches de l'éducation que vous donnez
          à vos enfants.
        </h1>
        <div class="ecran mx-4 mx-sm-8">
          <h2
            class="mt-8 mb-1 size-title-big"
            style="font-size: 60px;font-weight:normal"
          >
          Konsum
          </h2>
          <p
            class="mt-2 mb-10 size-body-big"
            style="font-size: 24px; color: #333333;"
          >
            <strong class="color-ecrans mt-2" style="display:inline-block;"
              >Interessieren Sie sich für die digitalen Medien, die Ihr Kind nutzt.
              </strong
            >
            Dadurch können Sie mit Ihrem Kind in Verbindung bleiben und schaffen eine gute Grundlage, um über die Bildschirmnutzung zu sprechen.
            <br /><strong
              class="color-ecrans mt-2"
              style="display:inline-block;"
              >Stellen Sie sicher, dass die digitalen Medien zum Alter Ihres Kindes passen.</strong
            >
            Ihr Kind kann noch nicht einschätzen, ob ein Spiel, ein Film oder eine Bildschirmform für sein Alter geeignet ist. Für
            Alterseinstufungen gibt es verschiedene Richtwerte (z.B. die PEGI-Richtlinien für Videospiele). Smartphones sind für Kleinkinder
            (0-6 Jahre) ungeeignet.
            <br /><strong
              class="color-ecrans mt-2"
              style="display:inline-block;"
              >Bevorzugen Sie digitale Medien, die Aktivität fördern.</strong
            >
            Das Gehirn Ihres Kindes entwickelt sich durch Aktivitäten, die alle Sinne ansprechen. Passives Konsumieren
            von digitalen Medien fördert diese Entwicklung nicht.
            <br /><strong
              class="color-ecrans mt-2"
              style="display:inline-block;"
              >Unterstützen Sie Ihr Kind dabei, sich jeweils nur auf eine Tätigkeit zu fokussieren.</strong
            >
            Bildschirme vermitteln den Eindruck, dass man mehrere Sachen gleichzeitig effizient machen kann. Das ist
            ein Mythos. Lernen Sie Ihrem Kind von klein auf, seine Aufmerksamkeit auf nur eine Tätigkeit zu lenken.
            <br /><strong
              class="color-ecrans mt-2"
              style="display:inline-block;"
              >Begrenzen Sie die Verfügbarkeit von Bildschirmen.</strong
            >
            Bildschirme wirken auf Kinder sehr anziehend. Tragbare Bildschirme noch mehr, da sie überall hin mitgenommen
            werden können. Lenken Sie die Aufmerksamkeit Ihres Kindes auf bildschrimfreie Aktivitäten, indem Sie die Anzahl Bildschirme zu Hause begrenzen.
          </p>
        </div>

        </v-card>
    </v-container>

    <v-container v-if="$route.query.pole == 'environnement'">
      <v-card class="py-5 px-2 px-sm-10 my-12">
        <h1 color="primary" class="mt-sm-6 mx-4 mx-sm-8 title-page-info">
          Choisissez les règles qui sont proches de l'éducation que vous donnez
          à vos enfants.
        </h1>

        <div class="environnement mx-4 mx-sm-8">
          <h2
            class="mt-8 mb-1 size-title-big"
            style="font-size: 60px;font-weight:normal"
          >
          Kontext
          </h2>
          <p
            class="mt-2 mb-10 size-body-big"
            style="font-size: 24px; color: #333333;"
          >
            <strong
              class="color-environnement mt-2"
              style="display:inline-block;"
              >Schaffen Sie klare Rahmenbedingungen.</strong
            >
            Vereinbaren Sie einfache Regeln, die festlegen, wann, wo und wie Ihr Kind die verschiedenen Formen von Bildschirmen, die Sie
            zu Hause haben, nutzen kann.
            <br /><strong
              class="color-environnement mt-2"
              style="display:inline-block;"
              >Platzieren Sie Bildschirme in gemeinsamen Wohnräumen.</strong
            >
            Ihr Kind ist in seinem Zimmer besser geschützt, sofern keine Bildschirme vorhanden sind. Zudem gewinnt sein Schlaf
            an Qualität. Geniessen Sie jedoch am Esstisch gemeinsam bildschirmfreie Zeit.
             <br /><strong
              class="color-environnement mt-2"
              style="display:inline-block;"
              >Wählen Sie für die Nutzung von Bildschirmen den richtigen Moment.</strong
            >
            Wenn es draussen schön ist oder Ihr Kind Hausaufgaben zu erledigen hat, ist es sinnvoll, die Bildschirmnutzung auf später
            zu verschieben.
             <br /><strong
              class="color-environnement mt-2"
              style="display:inline-block;"
              >Nutzen Sie Bildschirme bevorzugt im Familien- oder Freundeskreis.</strong
            >
            Zu den Hauptrisikofaktoren der Bildschirmnutzung gehört die Isolation. Die Nutzung von Bildschirmen in der Familie oder
            mit Freunden und das Teilen dieser Momente halten die Beziehungen zu anderen aufrecht.
            <br /><strong
              class="color-environnement mt-2"
              style="display:inline-block;"
              >Geniessen Sie bildschirmfreie Zeiten.</strong
            >
            Schlafen, essen, spielen, lesen – solche Momente lassen sich ohne nebenbei laufende Bildschirme besser geniessen.
            Solche bildschirmfreien Zeiten zeigen uns, dass es auch ohne geht.
          </p>
        </div>

        </v-card>
    </v-container>
  </v-content>
</template>

<script>
export default {
  name: 'Infos'
}
</script>

<style scoped>
.ecrans h2 {
  color: #62579d !important;
}
.ecrans p {
  color: #666666 !important;
}
.ecrans strong {
  color: #62579d !important;
}
.enfant h2 {
  color: #a6c700 !important;
}
.environnement h2 {
  color: #fbb700 !important;
}
.selected {
  background: #f1eabf;
  padding: 20px;
}

@media screen and (max-width: 599px) {
  .title-page-info {
    font-size: 25px !important;
  }
  .size-title-little {
    font-size: 25px !important;
  }
  .size-body-little {
    font-size: 16px !important;
  }

  .size-title-medium {
    font-size: 31px !important;
  }
  .size-body-medium {
    font-size: 18px !important;
  }

  .size-title-big {
    font-size: 40px !important;
  }
  .size-body-big {
    font-size: 20px !important;
  }
}
</style>
